import React, {useCallback, useMemo} from 'react';
import {Select, SelectOption, SelectOptGroup} from '@flixbus/honeycomb-react';
import {getCountryName} from 'Pages/Home/utils';
import {VehicleFuelType} from 'Pages/VehicleTypes/store/types';
import {VehicleTypeCostEntry} from 'Pages/ProductionCost/store/types';
import {VehicleTypeCostsSelectProps} from './VehicleTypeCostsSelect.types';

const VehicleTypeCostsSelect: React.FC<VehicleTypeCostsSelectProps> = ({
  busPartner,
  countries,
  vehicleTypes,
  selectedBusTypeCostEntryId,
  vehicleId,
  vehicleTypeCostEntries,
  errors,
  onChange
}) => {
  const countryName: string = useMemo(
    () => getCountryName(countries, busPartner?.countryId),
    [countries, busPartner?.countryId]
  );

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (!vehicleTypeCostEntries) return;
    const {busPartnerBusTypeCostEntries, countryBusTypeCostEntries} =
      vehicleTypeCostEntries;

    const selectedValue = event.target.value;

    const selectedBusPartnerBusTypeCostEntry =
      busPartnerBusTypeCostEntries.find(entry => entry.id === selectedValue);
    if (selectedBusPartnerBusTypeCostEntry) {
      onChange({
        busPartnerBusTypeCostEntryId: selectedBusPartnerBusTypeCostEntry.id,
        countryBusTypeCostEntryId: undefined
      });
      return;
    }

    const selectedCountryBusTypeCostEntry = countryBusTypeCostEntries.find(
      entry => entry.id === selectedValue
    );
    if (selectedCountryBusTypeCostEntry) {
      onChange({
        busPartnerBusTypeCostEntryId: undefined,
        countryBusTypeCostEntryId: selectedCountryBusTypeCostEntry.id
      });
    }
  };

  const getSelectOption = useCallback(
    (busTypeCost: VehicleTypeCostEntry) => {
      const vehicleType = vehicleTypes.find(
        (vehicleType: {id: string}) => vehicleType.id === busTypeCost.busTypeId
      );
      return (
        <SelectOption
          key={busTypeCost.id}
          value={busTypeCost.id}
          data-group="Bus Partner"
        >
          {`${vehicleType?.name}${
            busTypeCost.customIdentifier
              ? ` - ${busTypeCost.customIdentifier}`
              : ''
          }${
            busTypeCost.vehicleProductionYear
              ? ` - ${busTypeCost.vehicleProductionYear}`
              : ''
          }${
            vehicleType?.fuelType !== VehicleFuelType.DIESEL
              ? ` - ${vehicleType?.fuelType}`
              : ''
          }`}
        </SelectOption>
      );
    },
    [vehicleTypes]
  );

  const disabled = useMemo(() => {
    // disabled when can't match the Planr partner with a partner in CPC
    if (!busPartner?.id) return true;

    // disabled when no vehicle type cost entries are available
    return (
      !vehicleTypeCostEntries ||
      (vehicleTypeCostEntries.busPartnerBusTypeCostEntries.length === 0 &&
        vehicleTypeCostEntries.countryBusTypeCostEntries.length === 0)
    );
  }, [busPartner?.id, vehicleTypeCostEntries]);

  const busPartnerOptions = useMemo(() => {
    if (!vehicleTypeCostEntries) return;

    const {busPartnerBusTypeCostEntries} = vehicleTypeCostEntries;
    return (
      busPartnerBusTypeCostEntries.length > 0 && (
        <SelectOptGroup label="Bus Partner">
          {busPartnerBusTypeCostEntries?.map(busTypeCost =>
            getSelectOption(busTypeCost)
          )}
        </SelectOptGroup>
      )
    );
  }, [getSelectOption, vehicleTypeCostEntries]);

  const countryOptions = useMemo(() => {
    if (!vehicleTypeCostEntries) return;

    const {countryBusTypeCostEntries} = vehicleTypeCostEntries;
    return (
      countryBusTypeCostEntries.length > 0 && (
        <SelectOptGroup label={`Country: ${countryName}`}>
          {countryBusTypeCostEntries?.map(busTypeCost =>
            getSelectOption(busTypeCost)
          )}
        </SelectOptGroup>
      )
    );
  }, [countryName, getSelectOption, vehicleTypeCostEntries]);

  return (
    <Select
      id={`${vehicleId}-vehicle-type`}
      label="Vehicle type*"
      placeholder="Select a vehicle type"
      value={selectedBusTypeCostEntryId || ''}
      disabled={disabled}
      valid={!errors}
      infoError={errors?.optionality ?? errors?.nullable}
      onChange={handleSelectChange}
    >
      <>
        {busPartnerOptions}
        {countryOptions}
      </>
    </Select>
  );
};

export default React.memo(VehicleTypeCostsSelect);
