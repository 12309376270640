import {gql} from '@apollo/client';

const GET_PRODUCTION_COST_BUS_SCHEDULE_COST_FACTORS_VIEW = gql`
  query GetProductionCostBusScheduleCostFactorsView(
    $productionCostEntryId: ID!
    $productionCostLineId: ID!
    $busPartnerId: ID!
  ) {
    productionCostBusScheduleCostFactorsView(
      productionCostEntryId: $productionCostEntryId
      productionCostLineId: $productionCostLineId
      busPartnerId: $busPartnerId
    ) {
      scheduleName
      busName
      startDate
      endDate
      costFactors {
        countryDieselCost
        countryAdblueCost
        countryLngCost
        countryFlixStandardCost
        countryDriverConceptMultiplicationFactor
        countryDriverConceptDailyAllowance
        countryTiresCost
        countryBusTypeCustomIdentifier
        countryBusTypeVehicleProductionYear
        countryBusTypeLeasingCost
        countryBusTypeInsuranceCost
        countryBusTypeInterestCost
        countryBusTypeMotorVehicleTax
        countryBusTypeMaintenanceCostPerMonth
        countryBusTypeMaintenanceCostPerDistanceUnit
        countryBusTypeVehicleRegistrationCost
        countryDriverDaytimeWeekdaysWorkingHourCost
        countryDriverNighttimeWeekdaysWorkingHourCost
        countryDriverDaytimeWeekendsWorkingHourCost
        countryDriverNighttimeWeekendsWorkingHourCost
        countryDriverReplacementCostPerKm
        countryDriverReplacementCostPerHr
        countryDriverAccommodationCostPerNight
        busPartnerBusDieselCost
        busPartnerBusAdblueCost
        busPartnerBusLngCost
        busPartnerBusTiresCost
        busPartnerBusLeasingCost
        busPartnerBusInsuranceCost
        busPartnerBusInterestCost
        busPartnerBusMotorVehicleTax
        busPartnerBusMaintenanceCostPerMonth
        busPartnerBusMaintenanceCostPerDistanceUnit
        busPartnerBusVehicleRegistrationCost
        busPartnerBusCustomIdentifier
        busPartnerBusVehicleProductionYear
        busPartnerDriverDaytimeWeekdaysWorkingHourCost
        busPartnerDriverNighttimeWeekdaysWorkingHourCost
        busPartnerDriverDaytimeWeekendsWorkingHourCost
        busPartnerDriverNighttimeWeekendsWorkingHourCost
        busPartnerDriverReplacementCostPerKm
        busPartnerDriverReplacementCostPerHr
        busPartnerDriverAccommodationCostPerNight
        busPartnerOverheadCost
        busPartnerLegalCost
        busPartnerCleaningCost
        busPartnerRepairCostPerMonth
        busPartnerRepairCostPerDistanceUnit
        busPartnerOtherCost
        busTypeDieselConsumption
        busTypeAdblueConsumption
        busTypeLngConsumption
        busTypeNumberOfTires
        countryOtherOverheadCost
        countryOtherLegalCost
        countryOtherCleaningCost
        countryOtherRepairCostPerMonth
        countryOtherRepairCostPerDistanceUnit
        countryOtherCost
        currency
      }
      providedValues {
        id
        busPartnerId
        busPartnerBusTypeCostEntryId
        countryBusTypeCostEntryId
        countryRulesCountryId
        timeZone
        emptyHrPerDay
        emptyKmPerDay
        countryDriverConceptTypeId
        salaryChange
        accommodationsPerWeek
        accommodationCostPerNight
        replacementKmPerDay
        replacementHrPerDay
        additionalCostPerTrip
        totalDriverCostOverwrite
        numberOfDrivers
        driverSalary
        numberOfCrewMembers
        crewMemberSalary
        flixStandardCostOverwrite
        busUtilizationFactor
        currency
      }
    }
  }
`;

export default GET_PRODUCTION_COST_BUS_SCHEDULE_COST_FACTORS_VIEW;
