import React, {ReactNode, useCallback, useMemo} from 'react';
import {
  themeFlixDark,
  Themes,
  ThemeWrapper,
  useThemeSwitcher
} from '@flixbus/honeycomb-react';

export type ThemeContextModel = {
  theme: Themes;
  switchTheme: (theme: Themes) => void;
};

export const ThemeContext = React.createContext<ThemeContextModel | undefined>(
  undefined
);
ThemeContext.displayName = 'ThemeContext';

const ThemeProviderComp: React.FC<{children: ReactNode}> = ({children}) => {
  const localStorageKey = 'gbx-bdts-user-theme';

  const getThemeFromLocalStorage = useCallback(() => {
    try {
      return localStorage.getItem(localStorageKey);
    } catch {
      return null;
    }
  }, []);

  // Determine if the user prefers dark theme
  const darkThemeByDefault = useMemo(() => {
    const themeFromLocalStorage = getThemeFromLocalStorage();
    return themeFromLocalStorage === 'flix-dark';
  }, [getThemeFromLocalStorage]);

  const [theme, , setTheme] = useThemeSwitcher({
    lightTheme: 'flix',
    darkTheme: 'flix-dark',
    switchCondition: darkThemeByDefault
  });

  const switchTheme = useCallback(
    (theme: Themes) => {
      try {
        localStorage.setItem(localStorageKey, theme);
      } catch {
        console.warn('Failed to save theme in localStorage');
      }
      setTheme(theme);
    },
    [setTheme]
  );

  const value = useMemo(() => ({theme, switchTheme}), [theme, switchTheme]);

  return (
    <ThemeContext.Provider value={value}>
      <ThemeWrapper
        theme={theme}
        themes={{'flix-dark': themeFlixDark}}
        className="full-height"
        applyToRoot
      >
        {children}
      </ThemeWrapper>
    </ThemeContext.Provider>
  );
};

export const ThemeProvider = React.memo(ThemeProviderComp);

export const useThemeContext = (): ThemeContextModel => {
  const context = React.useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeContext must be used within ThemeProvider');
  }
  return context;
};
