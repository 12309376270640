import {Spinner, Text} from '@flixbus/honeycomb-react';
import React, {useEffect, useState, useMemo, useCallback} from 'react';
import {inputCostFactorsViewHeaders} from './constants';
import {isBlank, isNilOrEmpty, isNotNil, isNotNilOrEmpty} from 'ramda-adjunct';
import {isEmpty, isNil} from 'ramda';
import {getProductionCostBusScheduleCostFactorsView} from './graphqlRequests';
import {convertDecimalToPercentage} from '../../../Common/Utils';
import {useDispatch, useSelector} from 'react-redux';
import {getCountryName, getVehicleTypeName} from '../../utils';
import {getVehicleTypes} from '../../../VehicleTypes/store/thunks';
import {useLazyQuery} from '@apollo/client';
import GET_BUS_PARTNER_AND_COUNTRY_BUS_TYPE_COST_ENTRIES_BY_IDS from '../../graphql/getBusPartnerAndCountryBusTypeCostEntriesByIds';
import {useLocalUnitsContext} from '../../../Common/LocalUnits';
import Table from 'components/Table/Table';
import {getRepairCosts} from './Utils';

const ViewInputCostFactorTable = ({
  productionCostEntryId,
  productionCostLineId,
  busPartnerId,
  busPartnerCountry
}) => {
  const dispatch = useDispatch();
  const {localUnits} = useLocalUnitsContext();
  const vehicleTypes = useSelector(state => state.vehicleTypes?.list);
  const countries = useSelector(state => state.countries?.list);
  const [busTypeCostEntriesIdNameMap, setBusTypeCostEntriesIdNameMap] =
    useState();

  const [
    getBusTypeIds,
    {data: busPartnerAndCountryBusTypeCostEntriesByIdsData}
  ] = useLazyQuery(GET_BUS_PARTNER_AND_COUNTRY_BUS_TYPE_COST_ENTRIES_BY_IDS);

  const [inputCostFactorsTableRows, setInputCostFactorsTableRows] =
    useState(null);
  const [costInputFactorsEntries, setCostInputFactorsEntries] = useState([]);

  const loadBusTypes = useCallback(
    costInputFactors => {
      const busPartnerBusTypeCostEntryIds = extractProvidedValuesEntryIds(
        costInputFactors,
        'busPartnerBusTypeCostEntryId'
      );

      const countryBusTypeCostEntryIds = extractProvidedValuesEntryIds(
        costInputFactors,
        'countryBusTypeCostEntryId'
      );

      void getBusTypeIds({
        variables: {
          busPartnerBusTypeCostEntryIds: [...busPartnerBusTypeCostEntryIds],
          countryBusTypeCostEntryIds: [...countryBusTypeCostEntryIds]
        },
        fetchPolicy: 'no-cache'
      });
    },
    [getBusTypeIds]
  );

  const loadInputCostFactorsTable = useCallback(
    entries => {
      if (isNotNil(entries)) {
        loadBusTypes(entries);
        setCostInputFactorsEntries(entries);
      }
    },
    [loadBusTypes]
  );

  useEffect(() => {
    getProductionCostBusScheduleCostFactorsView(
      productionCostEntryId,
      productionCostLineId,
      busPartnerId,
      loadInputCostFactorsTable
    );
  }, [
    productionCostEntryId,
    productionCostLineId,
    busPartnerId,
    loadInputCostFactorsTable
  ]);

  useEffect(() => {
    if (isNilOrEmpty(vehicleTypes)) {
      dispatch(getVehicleTypes());
    }
  }, [vehicleTypes, dispatch]);

  useEffect(() => {
    if (
      busPartnerAndCountryBusTypeCostEntriesByIdsData &&
      isNotNilOrEmpty(vehicleTypes)
    ) {
      const busTypeCostEntriesIdNameMapInit = new Map();
      [
        ...busPartnerAndCountryBusTypeCostEntriesByIdsData.busPartnerBusTypeCostEntriesByIds,
        ...busPartnerAndCountryBusTypeCostEntriesByIdsData.countryBusTypeCostEntriesByIds
      ].forEach(busTypeCostEntry =>
        busTypeCostEntriesIdNameMapInit.set(
          busTypeCostEntry.id,
          getVehicleTypeName(vehicleTypes, busTypeCostEntry.busTypeId)
        )
      );

      setBusTypeCostEntriesIdNameMap(busTypeCostEntriesIdNameMapInit);
    }
  }, [busPartnerAndCountryBusTypeCostEntriesByIdsData, vehicleTypes]);

  const getInputCostFactorsRows = useCallback(
    filteredCostInputFactorsWithProvidedValuesEntriesParam => {
      let result = [];
      if (
        isNotNilOrEmpty(filteredCostInputFactorsWithProvidedValuesEntriesParam)
      ) {
        result = filteredCostInputFactorsWithProvidedValuesEntriesParam.map(
          costInputFactorsEntry => {
            const {costFactors} = costInputFactorsEntry;
            const {providedValues} = costInputFactorsEntry;

            // TODO: split into smaller objects (vehicle costs, driver costs, other costs and so on)
            return {
              scheduleName: costInputFactorsEntry.scheduleName,
              startDate: costInputFactorsEntry.startDate,
              endDate: costInputFactorsEntry.endDate,
              busName: costInputFactorsEntry.busName,
              busTypeCostEntryId: busTypeCostEntriesIdNameMap.get(
                providedValues.busPartnerBusTypeCostEntryId ??
                  providedValues.countryBusTypeCostEntryId
              ),
              busTypeCustomIdentifier:
                costFactors?.busPartnerBusCustomIdentifier ??
                costFactors?.countryBusTypeCustomIdentifier,
              vehicleProductionYear:
                costFactors?.busPartnerBusVehicleProductionYear ??
                costFactors?.countryBusTypeVehicleProductionYear,
              country: getCountryName(
                countries,
                providedValues.countryRulesCountryId
              ),
              timeZone: providedValues.timeZone,
              countryFlixStandardCost: costFactors?.countryFlixStandardCost,
              countryDriverConceptMultiplicationFactor:
                costFactors?.countryDriverConceptMultiplicationFactor,
              countryDriverConceptDailyAllowance:
                costFactors?.countryDriverConceptDailyAllowance,
              dieselCost: getDefaultOrProvidedValue(
                costFactors?.countryDieselCost,
                costFactors?.busPartnerBusDieselCost
              ),
              adblueCost: getDefaultOrProvidedValue(
                costFactors?.countryAdblueCost,
                costFactors?.busPartnerBusAdblueCost
              ),
              lngCost: getDefaultOrProvidedValue(
                costFactors?.countryLngCost,
                costFactors?.busPartnerBusLngCost
              ),
              costPerTire:
                costFactors?.busPartnerBusTiresCost ??
                costFactors?.countryTiresCost,
              leasingCost:
                costFactors?.busPartnerBusLeasingCost ??
                costFactors?.countryBusTypeLeasingCost,
              insuranceCost:
                costFactors?.busPartnerBusInsuranceCost ??
                costFactors?.countryBusTypeInsuranceCost,
              interestCost:
                costFactors?.busPartnerBusInterestCost ??
                costFactors?.countryBusTypeInterestCost,
              motorVehicleTax:
                costFactors?.busPartnerBusMotorVehicleTax ??
                costFactors?.countryBusTypeMotorVehicleTax,
              maintenanceCostPerMonth:
                costFactors?.busPartnerBusMaintenanceCostPerMonth ??
                costFactors?.countryBusTypeMaintenanceCostPerMonth,
              maintenanceCostPerDistanceUnit:
                costFactors?.busPartnerBusMaintenanceCostPerDistanceUnit ??
                costFactors?.countryBusTypeMaintenanceCostPerDistanceUnit,
              vehicleRegistrationCost:
                costFactors?.busPartnerBusVehicleRegistrationCost ??
                costFactors?.countryBusTypeVehicleRegistrationCost,

              driverDaytimeWeekdaysWorkingHourCost:
                costFactors?.busPartnerDriverDaytimeWeekdaysWorkingHourCost ??
                costFactors?.countryDriverDaytimeWeekdaysWorkingHourCost,
              driverNighttimeWeekdaysWorkingHourCost:
                costFactors?.busPartnerDriverNighttimeWeekdaysWorkingHourCost ??
                costFactors?.countryDriverNighttimeWeekdaysWorkingHourCost,
              driverDaytimeWeekendsWorkingHourCost:
                costFactors?.busPartnerDriverDaytimeWeekendsWorkingHourCost ??
                costFactors?.countryDriverDaytimeWeekendsWorkingHourCost,
              driverNighttimeWeekendsWorkingHourCost:
                costFactors?.busPartnerDriverNighttimeWeekendsWorkingHourCost ??
                costFactors?.countryDriverNighttimeWeekendsWorkingHourCost,
              driverReplacementCostPerKm:
                costFactors?.busPartnerDriverReplacementCostPerKm ??
                costFactors?.countryDriverReplacementCostPerKm,
              driverReplacementCostPerHr:
                costFactors?.busPartnerDriverReplacementCostPerHr ??
                costFactors?.countryDriverReplacementCostPerHr,
              accommodationCostPerNight:
                providedValues.accommodationCostPerNight ??
                costFactors?.busPartnerDriverAccommodationCostPerNight ??
                costFactors?.countryDriverAccommodationCostPerNight,
              overheadCost:
                costFactors?.busPartnerOverheadCost ??
                costFactors?.countryOtherOverheadCost,
              legalCost:
                costFactors?.busPartnerLegalCost ??
                costFactors?.countryOtherLegalCost,
              cleaningCost:
                costFactors?.busPartnerCleaningCost ??
                costFactors?.countryOtherCleaningCost,
              ...getRepairCosts(costFactors),
              otherCost:
                costFactors?.busPartnerOtherCost ??
                costFactors?.countryOtherCost,
              dieselConsumption: costFactors?.busTypeDieselConsumption,
              adblueConsumption: costFactors?.busTypeAdblueConsumption,
              lngConsumption: costFactors?.busTypeLngConsumption,
              numberOfTires: costFactors?.busTypeNumberOfTires,
              emptyKmPerDay: providedValues.emptyKmPerDay,
              emptyHrPerDay: providedValues.emptyHrPerDay,
              salaryChange: providedValues.salaryChange,
              accommodationsPerWeek: providedValues.accommodationsPerWeek,
              replacementKmPerDay: providedValues.replacementKmPerDay,
              replacementHrPerDay: providedValues.replacementHrPerDay,
              additionalCostPerTrip: providedValues.additionalCostPerTrip,
              totalDriverCostOverwrite: providedValues.totalDriverCostOverwrite,
              numberOfDrivers: providedValues.numberOfDrivers,
              driverSalary: providedValues.driverSalary,
              numberOfCrewMembers: providedValues.numberOfCrewMembers,
              crewMemberSalary: providedValues.crewMemberSalary,
              busUtilizationFactor: convertDecimalToPercentage(
                providedValues.busUtilizationFactor
              ),
              flixStandardCostOverwrite:
                providedValues.flixStandardCostOverwrite
            };
          }
        );
      }
      return result;
    },
    [busTypeCostEntriesIdNameMap, countries]
  );

  useEffect(() => {
    if (
      isNotNilOrEmpty(vehicleTypes) &&
      isNotNilOrEmpty(busTypeCostEntriesIdNameMap)
    ) {
      setInputCostFactorsTableRows(
        getInputCostFactorsRows(costInputFactorsEntries)
      );
    }
  }, [
    costInputFactorsEntries,
    vehicleTypes,
    busTypeCostEntriesIdNameMap,
    getInputCostFactorsRows
  ]);

  const tableHeaders = useMemo(() => {
    const hasValue = header =>
      inputCostFactorsTableRows?.some(entry => entry[header]);

    const hideIfNoValueColumns = [
      'maintenanceCostPerDistanceUnit',
      'maintenanceCostPerMonth',
      'repairCostPerDistanceUnit',
      'repairCostPerMonth'
    ];

    let trolo = inputCostFactorsViewHeaders(localUnits);
    return trolo
      .filter(header =>
        hideIfNoValueColumns.includes(header.key) ? hasValue(header.key) : true
      )
      .filter(header =>
        header.key === 'vehicleRegistrationCost'
          ? busPartnerCountry.vehicleRegistrationCostAllowed
          : true
      );
  }, [
    localUnits,
    inputCostFactorsTableRows,
    busPartnerCountry.vehicleRegistrationCostAllowed
  ]);

  const getDefaultOrProvidedValue = (defaultValue, providedValue) => {
    return isBlank(providedValue) ? defaultValue : providedValue;
  };

  const extractProvidedValuesEntryIds = (inputFactors, key) =>
    inputFactors.reduce((idsSet, costInputFactor) => {
      const costEntryId = costInputFactor.providedValues[key];
      if (costEntryId) idsSet.add(costEntryId);
      return idsSet;
    }, new Set());

  const inputCostFactorKey = productionCostEntryId
    .concat(productionCostLineId)
    .concat(busPartnerId);

  return (
    <div id={inputCostFactorKey}>
      {isNil(inputCostFactorsTableRows) ? (
        <div aria-live="polite" aria-busy="true" data-testid={'spinner'}>
          <Spinner />
        </div>
      ) : isEmpty(inputCostFactorsTableRows) ? (
        <Text>No Data Found</Text>
      ) : (
        <Table
          headers={tableHeaders}
          rows={inputCostFactorsTableRows}
          extraDataTableProps={{
            scrollable: 'sticky-header',
            extraClasses: `calculations-table`,
            height: '500px'
          }}
        />
      )}
    </div>
  );
};

export default ViewInputCostFactorTable;
