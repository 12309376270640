import {ValidationError} from 'yup';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useAppSelector} from 'Pages/Common/hooks/useAppSelector';
import {updateBusValuesValidationErrors} from 'Pages/ProductionCost/store/slice';
import {validateBusValues} from 'Pages/ProductionCost/utils/validateVehicles';
import {
  ProductionCostBusProvidedValuesEntry,
  ProductionCostBusProvidedValuesEntryErrors
} from 'Pages/ProductionCost/store/types';

const useValidateBusValues = () => {
  const countries = useAppSelector(({countries}) => countries.list);
  const busPartners = useAppSelector(({partners}) => partners.list);

  const costYear = useAppSelector(
    ({productionCost}) => productionCost.costYear
  );
  const vehicleTypeCostEntries = useAppSelector(
    ({productionCost}) => productionCost.vehicleTypeCostEntries
  );
  const dispatch = useDispatch();

  const getValidationErrors = useCallback(
    (vehicle: ProductionCostBusProvidedValuesEntry) => {
      const busPartnerId = vehicle.busPartnerId as string;
      const countryRulesCountryId = vehicle.countryRulesCountryId as string;

      const country = countries?.find(
        country => country.id === countryRulesCountryId
      );
      const busPartner = busPartners?.find(
        partner => partner.id === busPartnerId
      );

      const vehicleTypeCostEntry =
        vehicleTypeCostEntries[busPartnerId + countryRulesCountryId + costYear];

      const validationErrors: ProductionCostBusProvidedValuesEntryErrors = {};

      try {
        validateBusValues(vehicle, country, busPartner, vehicleTypeCostEntry);
        return null; // No validation errors
      } catch (error) {
        if (error instanceof ValidationError) {
          error.inner.forEach(({path, type, message}) => {
            const key = path || 'default';

            if (!validationErrors[key]) {
              validationErrors[key] = {};
            }
            validationErrors[key][type] = message;
          });
        }
      }

      return validationErrors;
    },
    [countries, busPartners, vehicleTypeCostEntries, costYear]
  );

  const validateForm = useCallback(
    (vehicle: ProductionCostBusProvidedValuesEntry) => {
      dispatch(
        updateBusValuesValidationErrors({
          validationErrors: getValidationErrors(vehicle),
          vehicleId: vehicle.vehicleId
        })
      );
    },
    [dispatch, getValidationErrors]
  );

  return validateForm;
};

export default useValidateBusValues;
