import {Nullable} from 'Pages/Common/types';
import {
  createVehicleTypeCost,
  updateVehicleTypeCost
} from 'Pages/PartnerCosts/store/thunks';
import VehicleCostsPanel from 'components/VehicleCosts/VehicleCostsPanel/VehicleCostsPanel';
import {PartnerCostsBusTypeCost} from 'Pages/PartnerCosts/store/types';
import React, {useCallback} from 'react';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {PartnerCostsVehicleCostPanelProps} from './PartnerCostsVehicleCostPanel.data';

const PartnerCostsVehicleCostPanel: React.FC<
  PartnerCostsVehicleCostPanelProps
> = ({
  partnerId,
  year,
  currency,
  vehicleTypes,
  localUnits,
  panelState,
  setPanelState,
  partnerCountry
}) => {
  const dispatch = useAppDispatch();

  const onSaveCompleted = useCallback(
    (vehicleTypeCost: Nullable<PartnerCostsBusTypeCost>) =>
      (error?: string) => {
        if (error) {
          setPanelState({
            data: vehicleTypeCost,
            loading: false,
            error
          });
        } else {
          setPanelState({
            data: null,
            loading: false,
            error: null
          });
        }
      },
    [setPanelState]
  );

  const onCreateVehicleTypeCost = useCallback(
    (vehicleTypeCost: Nullable<PartnerCostsBusTypeCost>) => {
      dispatch(
        createVehicleTypeCost(
          vehicleTypeCost,
          partnerId,
          year,
          onSaveCompleted(vehicleTypeCost)
        )
      );
    },
    [partnerId, year, dispatch, onSaveCompleted]
  );

  const onUpdateVehicleTypeCost = useCallback(
    (vehicleTypeCost: Nullable<PartnerCostsBusTypeCost>) => {
      dispatch(
        updateVehicleTypeCost(
          vehicleTypeCost,
          partnerId,
          year,
          onSaveCompleted(vehicleTypeCost)
        )
      );
    },
    [partnerId, year, dispatch, onSaveCompleted]
  );

  return (
    <VehicleCostsPanel
      country={partnerCountry}
      currency={currency}
      vehicleTypes={vehicleTypes}
      localUnits={localUnits}
      state={panelState}
      onSuccess={vehicleTypeCost => {
        setPanelState({
          data: vehicleTypeCost,
          loading: true,
          error: null,
          saveButtonName: panelState.saveButtonName
        });

        vehicleTypeCost.id
          ? onUpdateVehicleTypeCost(vehicleTypeCost)
          : onCreateVehicleTypeCost(vehicleTypeCost);
      }}
      onCancel={() => {
        setPanelState({data: null, loading: false, error: null});
      }}
    />
  );
};

export default React.memo(PartnerCostsVehicleCostPanel);
