import React, {useCallback} from 'react';
import {useAccount} from '@azure/msal-react';
import {
  HeaderUserWidget,
  HeaderSubNavigation,
  NavItem
} from '@flixbus/honeycomb-react';
import {IconSun, IconNight} from '@flixbus/honeycomb-icons-react';
import {useThemeContext} from 'providers/ThemeProvider';

export const LoginWidget = () => {
  const account = useAccount();
  const {theme, switchTheme} = useThemeContext();

  const changeTheme = useCallback(
    (event, theme) => {
      event.preventDefault();
      switchTheme(theme);
    },
    [switchTheme]
  );

  return (
    <div className="flex-center" aria-label="Login widget">
      {account ? (
        <HeaderUserWidget
          extraClasses="header-user-widget"
          subMenu={
            <HeaderSubNavigation id="account-setup-dropdown">
              {theme === 'flix' ? (
                <NavItem
                  href="#"
                  onClick={e => changeTheme(e, 'flix-dark')}
                  InlineIcon={IconNight}
                  shouldCloseDialog
                >
                  Dark theme
                </NavItem>
              ) : (
                <NavItem
                  href="#"
                  onClick={e => changeTheme(e, 'flix')}
                  InlineIcon={IconSun}
                  shouldCloseDialog
                >
                  Light theme
                </NavItem>
              )}
            </HeaderSubNavigation>
          }
        >
          {account?.name || ''}
        </HeaderUserWidget>
      ) : (
        <HeaderUserWidget />
      )}
    </div>
  );
};
