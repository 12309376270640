import React, {useCallback, useMemo} from 'react';
import TooltipInput from 'components/TooltipInput/TooltipInput';
import fieldInfoContent from 'Pages/ProductionCost/constants/fieldInfoContent';
import {useAppDispatch} from 'Pages/Common/hooks/useAppDispatch';
import {updateVehicleEntryByIds} from 'Pages/ProductionCost/store/slice';
import {validateInputNumber} from 'Pages/Common/Utils';
import {NumberOfCrewMembersProps} from './NumberOfCrewMembers.types';
import styles from './NumberOfCrewMembers.module.scss';

const NumberOfCrewMembers: React.FC<NumberOfCrewMembersProps> = ({
  value,
  entryId,
  errors
}) => {
  const dispatch = useAppDispatch();

  const id = `entry-${entryId}-number-of-crew-members`;

  const tooltipContent = useMemo(
    () => fieldInfoContent.numberOfCrewMembers,
    []
  );

  const handleInputChange = useCallback(
    (editedValue: string) => {
      const numberOfCrewMembers =
        validateInputNumber(
          editedValue,
          value ? value.toString() : '',
          undefined,
          0
        ) ?? undefined;

      dispatch(
        updateVehicleEntryByIds({
          entryIds: [entryId],
          updates: {numberOfCrewMembers}
        })
      );
    },
    [entryId, value, dispatch]
  );

  const formattedValue = useMemo(
    () => (value ? value.toString() : ''),
    [value]
  );

  return (
    <span className={styles.container}>
      <TooltipInput
        id={id}
        name="number-of-crew-members"
        label="Number of crew members"
        value={formattedValue}
        tooltipContent={tooltipContent}
        valid={!errors}
        infoError={errors?.optionality ?? errors?.nullable}
        handleInputChange={handleInputChange}
      />
    </span>
  );
};

export default React.memo(NumberOfCrewMembers);
