import {gql} from '@apollo/client';

const IMPORT_PRODUCTION_COST_PLANR_DATA = gql`
  mutation importProductionCostPlanrData($id: ID!, $scheduleIds: [ID!]!) {
    importProductionCostPlanrData(
      productionCostEntryId: $id
      scheduleIds: $scheduleIds
    ) {
      driverShiftsCalculationTypeAllowed
      busProvidedValues {
        busPartnerBusTypeCostEntryId
        busPartnerId
        countryBusTypeCostEntryId
        countryRulesCountryId
        vehicleId
        vehicleName
      }
      busScheduleProvidedValues {
        description
        endDate
        lineNumber
        name
        scheduleId
        size
        startDate
        busScheduleProvidedValuesEntries {
          accommodationCostPerNight
          accommodationsPerWeek
          additionalCostPerTrip
          busUtilizationFactor
          countryDriverConceptTypeId
          currency
          driverSalary
          emptyHrPerDay
          emptyKmPerDay
          flixStandardCostOverwrite
          id
          numberOfDrivers
          numberOfCrewMembers
          crewMemberSalary
          replacementHrPerDay
          replacementKmPerDay
          salaryChange
          totalDriverCostOverwrite
          vehicleId
          avgDriverFactor
          avgWeeklyOpDays
          avgWeeklyScheduleHours
          avgWeeklyWorkingHours
          dailyAllowance
        }
      }
    }
  }
`;

export default IMPORT_PRODUCTION_COST_PLANR_DATA;
