import React, {useCallback} from 'react';
import {NavItem, NavSide, NavSideSubMenu, Text} from '@flixbus/honeycomb-react';
import {Icon, IconBus, IconCircleSolid} from '@flixbus/honeycomb-icons-react';
import {ProductionCostScheduleWithProvidedValues} from 'Pages/ProductionCost/store/types';
import {transformDate} from 'Pages/ProductionCost/components/DataImportStepTwo/Utils';
import {LineScheduleVehicleNavProps} from './LineScheduleVehicleNav.types';
import style from './LineScheduleVehicleNav.module.scss';

const LineScheduleVehicleNav: React.FC<LineScheduleVehicleNavProps> = ({
  lineNumber,
  schedules,
  busValues,
  activeSchedule,
  activeVehicleEntry,
  errors,
  onScheduleSelect,
  onVehicleEntrySelect
}) => {
  const scheduleVehicles = useCallback(
    schedule => (
      <NavSideSubMenu id={`schedule-${schedule.name}-vehicles`}>
        {schedule.busScheduleProvidedValuesEntries.map(entry => {
          const vehicleName =
            busValues.find(vehicle => entry.vehicleId === vehicle.vehicleId)
              ?.vehicleName ?? 'N/A';
          const isVehicleEntryInvalid = !!errors.busScheduleValues[entry.id];

          return (
            <NavItem
              active={activeVehicleEntry === entry.id}
              key={entry.id}
              InlineIcon={IconBus}
              onClick={() => onVehicleEntrySelect(entry)}
              aria-label={`Schedule ${schedule.name} vehicle ${vehicleName} button`}
            >
              <div className={style.vehicleName}>
                {vehicleName}
                {isVehicleEntryInvalid && (
                  <Icon
                    InlineIcon={IconCircleSolid}
                    size={2}
                    extraClasses="warning-color"
                    aria-label="Invalid vehicle icon"
                  />
                )}
              </div>
            </NavItem>
          );
        })}
      </NavSideSubMenu>
    ),
    [busValues, activeVehicleEntry, errors, onVehicleEntrySelect]
  );

  const scheduleDetails = useCallback(
    (schedule: ProductionCostScheduleWithProvidedValues) => {
      const startDate = transformDate(schedule.startDate);
      const endDate = transformDate(schedule.endDate);
      const isScheduleInvalid = schedule.busScheduleProvidedValuesEntries.some(
        entry => errors.busScheduleValues[entry.id]
      );

      return (
        <Text
          Elem="div"
          extraClasses="content-secondary-color"
          onClick={() => onScheduleSelect(schedule)}
        >
          <div className={style.scheduleDateRange}>
            {startDate} - {endDate}
            {isScheduleInvalid && (
              <Icon
                InlineIcon={IconCircleSolid}
                size={2}
                extraClasses="warning-color"
                aria-label="Invalid schedule icon"
              />
            )}
          </div>
          <div>{schedule.name}</div>
        </Text>
      );
    },
    [errors, onScheduleSelect]
  );

  return (
    <div className={style.container}>
      <h2 className={style.lineNumber}>Line {lineNumber ?? 'N/A'}</h2>

      <NavSide
        aria-label="Line schedule vehicle navigation"
        extraClasses="line-schedule-vehicle-nav"
      >
        {schedules.map(schedule => (
          <NavItem
            open={activeSchedule === schedule.scheduleId}
            key={schedule.scheduleId}
            subMenu={scheduleVehicles(schedule)}
            aria-label={`Schedule ${schedule.name} button`}
            onClick={() => onScheduleSelect(schedule)}
          >
            {scheduleDetails(schedule)}
          </NavItem>
        ))}
      </NavSide>
    </div>
  );
};

export default React.memo(LineScheduleVehicleNav);
