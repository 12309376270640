import {createSelector} from '@reduxjs/toolkit';
import {ProductionCostState} from './types';

export const selectLineScheduleById = createSelector(
  [
    ({busScheduleValues}: ProductionCostState) => busScheduleValues ?? [],
    (_, {scheduleId}: {scheduleId: string}) => scheduleId
  ],
  (busScheduleValues, scheduleId) =>
    busScheduleValues.find(busSchedule => busSchedule.scheduleId === scheduleId)
);

export const selectVehicleEntryById = createSelector(
  [
    ({busScheduleValues}: ProductionCostState) => busScheduleValues ?? [],
    (_, {vehicleEntryId}: {vehicleEntryId: string}) => vehicleEntryId
  ],
  (busScheduleValues, vehicleEntryId) => {
    for (const busSchedule of busScheduleValues) {
      const vehicleEntry = busSchedule.busScheduleProvidedValuesEntries.find(
        vehicleEntry => vehicleEntry.id === vehicleEntryId
      );

      if (vehicleEntry) return vehicleEntry;
    }
  }
);

export const selectVehicleEntryIdsByVehicleId = createSelector(
  [
    ({busScheduleValues}: ProductionCostState) => busScheduleValues ?? [],
    (_, {vehicleId}: {vehicleId: string}) => vehicleId
  ],
  (busScheduleValues, vehicleId) =>
    busScheduleValues
      .map(busSchedule =>
        busSchedule.busScheduleProvidedValuesEntries
          .filter(vehicleEntry => vehicleEntry.vehicleId === vehicleId)
          .map(vehicleEntry => vehicleEntry.id)
      )
      .flat()
);

export const selectVehicleTypeCostEntriesById = createSelector(
  [
    ({vehicleTypeCostEntries}: ProductionCostState) => vehicleTypeCostEntries,
    (_, {id}: {id: string}) => id
  ],
  (vehicleTypeCostEntries, id) => vehicleTypeCostEntries[id]
);
