import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import {defaultTo} from 'ramda';
import {isBlank, isNotNilOrEmpty} from 'ramda-adjunct';
import {
  Grid,
  GridCol,
  Input,
  Select,
  SelectOption
} from '@flixbus/honeycomb-react';
import TooltipInput from 'components/TooltipInput/TooltipInput';
import ContentLoader from 'Pages/Common/ContentLoader';
import CpcPanel from 'Pages/Common/CpcPanel';
import {RegionalCostsBusTypeCost} from 'Pages/RegionalCosts/store/types';
import {validateInputNumber, validateInputYear} from 'Pages/Common/Utils';
import {VehicleFuelType} from 'Pages/VehicleTypes/store/types';
import {
  VehicleCostsPanelData,
  VehicleCostsPanelProps
} from './VehicleCostsPanel.types';
import InputWithSelection from '../../InputWithSelection/InputWithSelection';
import {
  getValueDimensions,
  ValueDimension
} from 'Pages/Countries/components/index.constants';

const VehicleCostsPanel: React.FC<VehicleCostsPanelProps> = ({
  country,
  currency,
  vehicleTypes,
  localUnits,
  state,
  onSuccess,
  onCancel
}) => {
  const [panelData, setPanelData] = useState<VehicleCostsPanelData | null>(
    null
  );

  // is used for FE-side validation, whereas state.error is used for BE-side validation
  const [submitted, setSubmitted] = useState(false);

  const valueOrEmptyString = defaultTo('');

  const costDimensionOptions = useMemo(() => {
    return getValueDimensions(currency, localUnits);
  }, [currency, localUnits]);

  const convertBusTypeCostToPanelData = useCallback(
    (costs: RegionalCostsBusTypeCost) => {
      return {
        ...costs,
        maintenanceCost:
          costs.maintenanceCostPerMonth ?? costs.maintenanceCostPerDistanceUnit,
        maintenanceCostDimensionUnit: costs.maintenanceCostPerDistanceUnit
          ? ValueDimension.PER_DISTANCE_UNIT
          : costs.maintenanceCostPerMonth
          ? ValueDimension.PER_MONTH
          : country.maintenanceCostDimension,
        vehicleRegistrationCost: country.vehicleRegistrationCostAllowed
          ? costs.vehicleRegistrationCost
          : null
      } as VehicleCostsPanelData;
    },
    [country.maintenanceCostDimension, country.vehicleRegistrationCostAllowed]
  );

  const convertPanelDataToBusTypeCost = useCallback(
    (panelData: VehicleCostsPanelData) => {
      const {maintenanceCost, maintenanceCostDimensionUnit, ...restPanelData} =
        panelData;

      return {
        ...restPanelData,
        maintenanceCostPerMonth:
          ValueDimension.PER_MONTH === maintenanceCostDimensionUnit
            ? maintenanceCost
            : null,
        maintenanceCostPerDistanceUnit:
          ValueDimension.PER_DISTANCE_UNIT === maintenanceCostDimensionUnit
            ? maintenanceCost
            : null
      } as RegionalCostsBusTypeCost;
    },
    []
  );

  useEffect(() => {
    const parsedData = state.data ? {...state.data} : null;

    const numberFields = [
      'leasingCost',
      'interestCost',
      'insuranceCost',
      'motorVehicleTax',
      'maintenanceCostPerMonth',
      'maintenanceCostPerDistanceUnit',
      'vehicleProductionYear',
      'vehicleRegistrationCost'
    ];

    if (parsedData !== null) {
      numberFields.forEach(field => {
        if (parsedData[field] && parsedData[field] !== '') {
          parsedData[field] = Number(parsedData[field]);
        }
      });
    }
    setPanelData(
      parsedData === null ? null : convertBusTypeCostToPanelData(parsedData)
    );
    setSubmitted(false);
  }, [state.data, convertBusTypeCostToPanelData]);

  const handleSaveVehicleType = () => {
    setSubmitted(true);
    if (panelData && !isBlank(panelData?.busTypeId)) {
      onSuccess(convertPanelDataToBusTypeCost(panelData));
    }
  };

  const vehicleTypeOptions = () => {
    return vehicleTypes.map(vehicleType => (
      <SelectOption key={vehicleType?.id} value={vehicleType?.id}>
        {vehicleType?.name}
      </SelectOption>
    ));
  };

  const onChangeInputValue = useCallback(
    (value: string | null, field: keyof VehicleCostsPanelData) => {
      if (field === 'busTypeId') {
        const busTypeId = value ?? null;
        const busType = vehicleTypes.find(
          vehicleType => vehicleType.id === busTypeId
        );
        const fuelType = busType?.fuelType ?? null;

        setPanelData(
          prevData =>
            ({
              ...prevData,
              busTypeId,
              fuelType
            } as VehicleCostsPanelData)
        );
        return;
      }

      let newValue: string | number | null = null;
      switch (field) {
        case 'vehicleProductionYear':
          newValue = validateInputYear(value);
          break;
        case 'customIdentifier':
        case 'maintenanceCostDimensionUnit':
          newValue = value;
          break;
        default:
          newValue = validateInputNumber(
            value,
            panelData?.[field] as string,
            ['leasingCost', 'maintenanceCost'].includes(field) ? 7 : 6
          );
          break;
      }
      setPanelData(
        prevData =>
          ({
            ...prevData,
            [field]: newValue ?? null
          } as VehicleCostsPanelData)
      );
    },
    [panelData, vehicleTypes]
  );

  const onChangeInput = (
    changeEvent: ChangeEvent<HTMLSelectElement | HTMLInputElement>,
    field: keyof VehicleCostsPanelData
  ) => {
    const {value} = changeEvent.target;
    onChangeInputValue(value, field);
  };

  const handleMaintenanceCostChange = useCallback(
    ({inputValue, selectorValue}) => {
      onChangeInputValue(selectorValue, 'maintenanceCostDimensionUnit');
      onChangeInputValue(inputValue, 'maintenanceCost');
    },
    [onChangeInputValue]
  );

  return (
    <CpcPanel
      title={
        isNotNilOrEmpty(panelData?.id)
          ? 'Edit costs per vehicle type'
          : 'Add costs per vehicle type'
      }
      activeAction={!!panelData}
      closeAction={onCancel}
      saveAction={handleSaveVehicleType}
      saveButtonName={state.saveButtonName}
    >
      {panelData ? (
        <Grid extraClasses="gap-2">
          <GridCol size={12}>
            <Select
              id="vehicle-costs-panel-busTypeId"
              label="Vehicle type"
              placeholder="Select a vehicle type"
              value={valueOrEmptyString(panelData.busTypeId)}
              valid={
                submitted ? !isBlank(panelData.busTypeId) : undefined // don't show error on initial load
              }
              onChange={e => onChangeInput(e, 'busTypeId')}
            >
              {vehicleTypeOptions()}
            </Select>
          </GridCol>

          <GridCol size={12}>
            <Input
              name="vehicle-costs-panel-fuelType"
              id="vehicle-costs-panel-fuelType"
              label="Fuel type"
              value={
                panelData.fuelType === VehicleFuelType.DIESEL
                  ? 'Diesel'
                  : panelData.fuelType === 'LNG'
                  ? 'LNG'
                  : ''
              }
              disabled
            />
          </GridCol>

          <GridCol size={12}>
            <Input
              name="vehicle-costs-panel-customIdentifier"
              id="vehicle-costs-panel-customIdentifier"
              label="Custom identifier"
              valid={state.error ? false : undefined}
              infoError={state.error}
              value={valueOrEmptyString(panelData.customIdentifier)}
              onChange={e => onChangeInput(e, 'customIdentifier')}
            />
          </GridCol>

          <GridCol size={12}>
            <Input
              type={`number`}
              name="vehicle-costs-panel-vehicleProductionYear"
              id="vehicle-costs-panel-vehicleProductionYear"
              label="Vehicle production year"
              value={valueOrEmptyString(panelData.vehicleProductionYear)}
              onChange={event => onChangeInput(event, 'vehicleProductionYear')}
            />
          </GridCol>

          <GridCol size={12}>
            <Input
              type={`number`}
              name="vehicle-costs-panel-leasingCost"
              id="vehicle-costs-panel-leasingCost"
              label="Leasing/amortisation cost*"
              inlineLabelLeft={`${currency}/month`}
              placeholder="0.1"
              value={valueOrEmptyString(panelData.leasingCost)}
              onChange={event => onChangeInput(event, 'leasingCost')}
              step={`0.1`}
            />
          </GridCol>

          <GridCol size={12}>
            <Input
              type={`number`}
              name="vehicle-costs-panel-insuranceCost"
              id="vehicle-costs-panel-insuranceCost"
              label="Insurance cost*"
              inlineLabelLeft={`${currency}/month`}
              placeholder="0.1"
              value={valueOrEmptyString(panelData.insuranceCost)}
              onChange={event => onChangeInput(event, 'insuranceCost')}
              step={`0.1`}
            />
          </GridCol>

          <GridCol size={12}>
            <Input
              type={`number`}
              name="vehicle-costs-panel-interestCost"
              id="vehicle-costs-panel-interestCost"
              label="Interest cost"
              inlineLabelLeft={`${currency}/month`}
              placeholder="0.1"
              value={valueOrEmptyString(panelData.interestCost)}
              onChange={event => onChangeInput(event, 'interestCost')}
              step={`0.1`}
            />
          </GridCol>

          <GridCol size={12}>
            <Input
              type={`number`}
              name="vehicle-costs-panel-motorVehicleTax"
              id="vehicle-costs-panel-motorVehicleTax"
              label="Motor vehicle tax cost"
              inlineLabelLeft={`${currency}/month`}
              placeholder="0.1"
              value={valueOrEmptyString(panelData.motorVehicleTax)}
              onChange={event => onChangeInput(event, 'motorVehicleTax')}
              step={`0.1`}
            />
          </GridCol>

          <GridCol size={12}>
            <InputWithSelection
              selectorOptions={costDimensionOptions}
              selectorValue={valueOrEmptyString(
                panelData.maintenanceCostDimensionUnit
              )}
              inputValue={valueOrEmptyString(panelData.maintenanceCost)}
              label="Service/maintenance cost*"
              onChange={handleMaintenanceCostChange}
            />
          </GridCol>

          {country.vehicleRegistrationCostAllowed ? (
            <GridCol size={12}>
              <TooltipInput
                id="vehicle-costs-panel-vehicleRegistrationCost"
                name="vehicle-costs-panel-vehicleRegistrationCost"
                label="Vehicle registration cost*"
                placeholder="0.1"
                inlineLabelLeft={`${currency}/month`}
                value={valueOrEmptyString(panelData.vehicleRegistrationCost)}
                step={0.1}
                tooltipContent="State-dependent fees, including annual/quarterly passing charges for the vehicle."
                tooltipPosition="top"
                handleInputChange={value =>
                  onChangeInputValue(value, 'vehicleRegistrationCost')
                }
              />
            </GridCol>
          ) : (
            <></>
          )}
        </Grid>
      ) : (
        <ContentLoader />
      )}
    </CpcPanel>
  );
};

export default React.memo(VehicleCostsPanel);
